<template>
  <base-layout>
    <PassAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.subscriptionManagement.headline')"
            @plus="add"
            :hide-plus="$acl.canNotCreate('pass')"
          />

          <div class="flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="summary-card-container">
          <SummaryCard
            :title="
              $t(
                'components.subscriptionManagement.summary.totalSubscriptionUsers'
              )
            "
            :value="indexMetaData.summary.total_pass_user"
            variant="gray"
            size="large"
          />

          <SummaryCard
            :title="
              $t(
                'components.subscriptionManagement.summary.revenueFromSubscription'
              )
            "
            :value="indexMetaData.summary.earning_from_pass"
            :symbol="getCurrencySymbol(indexMetaData.summary.currency)"
            variant="gray"
            size="large"
          />
        </div>
      </div>
      <div
        v-if="$acl.canNotView('pass')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`passIndex`"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="`passIndex`"
              :slot-width="slotWidth"
              :options="getFilteredItems"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem
                  :text="item.id"
                  :truncate="-5"
                  :to="{ name: 'PassDetails', params: { id: item.id } }"
                />

                <FSTableRowItem :text="getPassCategoryName(item.category)" />

                <FSTableRowItem>
                  <RouterLink
                    v-if="item.assigned_fleet"
                    class="text-blue-600 "
                    :key="item.assigned_fleet.id"
                    :to="{
                      name: 'ViewFleetProfile',
                      params: { id: item.assigned_fleet.id },
                    }"
                    target="_blank"
                    >{{ item.assigned_fleet.name }}</RouterLink
                  >
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ getDate(dateZuluFormatter(item.end_date)) }}
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ getTimesLeft(item.start_date, item.end_date) }}
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ formatPrice({ amount: item.amount, item }) }}
                  <p v-if="item.category === 1">
                    ({{ item.free_riding_time_minutes }} Minutes)
                  </p>
                </FSTableRowItem>

                <FSTableRowItem>
                  <XStatus
                    :text="item.status"
                    :variant="getItemVariant(item.status)"
                    profile="payment"
                  />
                </FSTableRowItem>

                <FSTableRowItem>
                  <div class="flex items-center gap-1">
                    <OtoEditIcon
                      v-if="item.status !== 'Expired'"
                      @click="edit(item)"
                    />
                    <MoreActions
                      :data="item"
                      @refresh="$store.dispatch('fsTable/fetchData')"
                    />
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="getPassCategoryName(item.category)"
                  :to="{
                    name: 'PassDetails',
                    params: { id: item.id },
                  }"
                  target="_blank"
                />
                <FSTableRowItem>
                  {{ formatPrice({ amount: item.amount, item }) }}
                  <p v-if="item.category === 1">
                    ({{ item.free_riding_time_minutes }} Minutes)
                  </p>
                </FSTableRowItem>
                <FSTableRowItem>
                  <XStatus
                    :text="item.status"
                    :variant="getItemVariant(item.status)"
                    profile="payment"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.subscriptionManagement.table.columns.id')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.subscriptionManagement.table.columns.fleet'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <RouterLink
                        v-if="item.assigned_fleet"
                        class="text-blue-600 "
                        :key="item.assigned_fleet.id"
                        :to="{
                          name: 'ViewFleetProfile',
                          params: { id: item.assigned_fleet.id },
                        }"
                        target="_blank"
                        >{{ item.assigned_fleet.name }}</RouterLink
                      >
                      <template v-else>
                        <div>
                          --
                        </div>
                      </template>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.subscriptionManagement.table.columns.expireAt'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getDate(dateZuluFormatter(item.end_date)) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.subscriptionManagement.table.columns.timeLeft'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getTimesLeft(item.start_date, item.end_date) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.subscriptionManagement.table.columns.actions'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div class="flex items-center gap-1">
                        <OtoEditIcon
                          v-if="item.status !== 'Expired'"
                          @click="edit(item)"
                        />
                        <MoreActions
                          :data="item"
                          @refresh="$store.dispatch('fsTable/fetchData')"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import SummaryCard from '@/components/cards/SummaryCard'
import MoreActions from './MoreActions.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import { PassConfig } from '@/config/PassConfig'

import { EventBus } from '@/utils/EventBus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import { getDate, dateZuluFormatter } from '@/utils'
import PassAddEdit from '@/views/pass/PassAddEdit'
import XStatus from '@/components/badge/XStatus'

export default {
  name: 'PassManagement',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,

    OtoEditIcon,
    DateRangePicker,
    PassAddEdit,
    XStatus,
    MoreActions,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    SummaryCard,
  },
  props: {
    filterItems: {
      type: Array,
      default: () => [],
    },
  },
  async created() {
    await this.generateFilterOptions()
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total_pass_user: 0,
          earning_from_pass: 0,
        },
      },
      indexDataEndpoint: PassConfig.api.index,
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.id'
          )}`,
          width: '5%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.subscriptionType'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.fleet'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.expireAt'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.timeLeft'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.subscriptionAmount'
          )}`,
          width: '13%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.status'
          )}`,
          width: '10%',
          sort: 'is_active',
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.actions'
          )}`,
          width: '5%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },

        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.subscriptionType'
          )}`,
          width: '40%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.subscriptionAmount'
          )}`,
          width: '30%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.subscriptionManagement.table.columns.status'
          )}`,
          width: '20%',
          sort: 'is_active',
        },
      ],
      filterOptions: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onApplyFilterDateRange,
    getDate,
    dateZuluFormatter,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getTimeDiff(timeStr) {
      const currentTime = new Date()
      const focusTime = new Date(this.dateZuluFormatter(timeStr))
      focusTime.setHours(23)
      focusTime.setMinutes(59)
      focusTime.setSeconds(59)
      const diffTime = focusTime - currentTime
      return diffTime
    },
    getTimesLeft(starTimeStr, endTimeStr) {
      if (starTimeStr && endTimeStr) {
        const startTimeDiff = this.getTimeDiff(starTimeStr)
        const endTimeDiff = this.getTimeDiff(endTimeStr)

        // console.log('diffTime', startTimeDiff, startTimeDiff)
        if (startTimeDiff > 86400000) {
          return this.getDate(starTimeStr)
        } else {
          return this.milisecToDHMSConverter(endTimeDiff)
        }
      } else {
        return '--'
      }
    },
    milisecToDHMSConverter(ms) {
      const years = Math.floor(ms / (12 * 30 * 24 * 60 * 60 * 1000))
      const yearsms = ms % (12 * 30 * 24 * 60 * 60 * 1000)
      const months = Math.floor(yearsms / (30 * 24 * 60 * 60 * 1000))
      const monthsms = ms % (30 * 24 * 60 * 60 * 1000)
      const days = Math.floor(monthsms / (24 * 60 * 60 * 1000))
      const daysms = ms % (24 * 60 * 60 * 1000)
      const hours = Math.floor(daysms / (60 * 60 * 1000))
      const hoursms = ms % (60 * 60 * 1000)
      const minutes = Math.floor(hoursms / (60 * 1000))
      const minutesms = ms % (60 * 1000)
      const seconds = Math.floor(minutesms / 1000)

      if (years > 0) {
        if (months > 0) {
          return `${years} y ${months} mo left`
        } else {
          return `${years} y left`
        }
      }
      if (months > 0) {
        if (days > 0) {
          return `${months} mo ${days} d left`
        } else {
          return `${months} mo left`
        }
      }
      if (days > 0 && months <= 0) {
        if (hours > 0 && minutes > 0) {
          return `${days} d ${hours} h left`
        }
        if (hours > 0) {
          return `${days} d ${hours} h  left`
        }

        if (hours === 0 && minutes > 0) {
          return `${days} d  ${minutes} m left`
        }
        if (hours === 0 && minutes === 0) {
          return `${days} d left`
        }
      }
      if (hours > 0 && days <= 0 && months <= 0) {
        if (minutes > 0) {
          return `${hours} h ${minutes} m left`
        }

        if (minutes <= 0) {
          return `${hours} h left`
        }
      }
      if (minutes > 0 && hours <= 0 && days <= 0 && months <= 0) {
        if (seconds > 0) {
          return `${minutes} m ${seconds} s left`
        } else {
          return `${minutes} m left`
        }
      }
      if (
        seconds > 0 &&
        minutes <= 0 &&
        hours <= 0 &&
        days <= 0 &&
        months <= 0
      ) {
        return `${seconds} s left`
      }
      if (
        seconds <= 0 &&
        minutes <= 0 &&
        hours <= 0 &&
        days <= 0 &&
        months <= 0
      ) {
        return this.getDate(`2023-06-06T00:00:00Z`)
      }
    },
    async generateFilterOptions() {
      let filters = []

      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/?dropdown').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },
    getPassCategoryName(category) {
      const passCategoryChoices = {
        0: 'Subscription Prime (Credit)',
        1: 'Subscription (Time)',
      }
      return passCategoryChoices[category]
    },
    add: function() {
      EventBus.$emit(PassConfig.events.editingData, {
        // enable status-switch by default
        status_active: true,
      })
      dispatchEvent(new Event(PassConfig.events.sorToggle))
      // console.log("asdfdsaf");
    },
    edit: function(item) {
      if (this.$acl.canNotUpdate('pass')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'pass',
          })
        )
        return
      }
      const pass = { ...item, assigned_fleet: item.assigned_fleet?.id ?? null }

      EventBus.$emit(PassConfig.events.editingData, pass)
      dispatchEvent(new Event(PassConfig.events.sorToggle))
    },
    onOpenES() {
      console.log('onES')
    },
    getItemVariant(data) {
      if (data === 'Active') return 'green'
      if (data === 'Inactive') return 'gray'
      return 'red'
    },

    getCurrencySymbol(currency) {
      if (currency) {
        return currency.symbol
      }

      return this.currencySymbol
    },
    getPassCurrencySymbol(item) {
      const fleet = item.assigned_fleet

      if (fleet) {
        return fleet.country?.currency_symbol || this.currencySymbol
      }

      return this.currencySymbol
    },
    formatPrice({ amount, item } = {}) {
      if (!amount || typeof Number(amount) !== 'number') {
        return '--'
      }
      const c = this.getPassCurrencySymbol(item)
      return `${c} ${Number(amount).toFixed(2)}`
    },
    getFormattedDiscountRate(rate) {
      if (rate === null || typeof rate === 'undefined') {
        return '--'
      }

      return `${parseFloat(rate).toFixed(0)}%`
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
