var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('PassAddEdit',{on:{"add-edit":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('TitlePlus',{attrs:{"title":_vm.$t('components.subscriptionManagement.headline'),"hide-plus":_vm.$acl.canNotCreate('pass')},on:{"plus":_vm.add}}),_c('div',{staticClass:"flex md:justify-end"},[_c('DateRangePicker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)],1),_c('div',{staticClass:"summary-card-container"},[_c('SummaryCard',{attrs:{"title":_vm.$t(
              'components.subscriptionManagement.summary.totalSubscriptionUsers'
            ),"value":_vm.indexMetaData.summary.total_pass_user,"variant":"gray","size":"large"}}),_c('SummaryCard',{attrs:{"title":_vm.$t(
              'components.subscriptionManagement.summary.revenueFromSubscription'
            ),"value":_vm.indexMetaData.summary.earning_from_pass,"symbol":_vm.getCurrencySymbol(_vm.indexMetaData.summary.currency),"variant":"gray","size":"large"}})],1)]),(_vm.$acl.canNotView('pass'))?_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")]):_c('FSTable',{attrs:{"fst-id":"passIndex","headers":_vm.getTableHeaders,"endpoint":_vm.indexDataEndpoint},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
            var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":"passIndex","slot-width":slotWidth,"options":_vm.getFilteredItems}})],1)]}},{key:"default",fn:function(ref){
            var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5,"to":{ name: 'PassDetails', params: { id: item.id } }}}),_c('FSTableRowItem',{attrs:{"text":_vm.getPassCategoryName(item.category)}}),_c('FSTableRowItem',[(item.assigned_fleet)?_c('RouterLink',{key:item.assigned_fleet.id,staticClass:"text-blue-600 ",attrs:{"to":{
                    name: 'ViewFleetProfile',
                    params: { id: item.assigned_fleet.id },
                  },"target":"_blank"}},[_vm._v(_vm._s(item.assigned_fleet.name))]):_vm._e()],1),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm.getDate(_vm.dateZuluFormatter(item.end_date)))+" ")]),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm.getTimesLeft(item.start_date, item.end_date))+" ")]),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm.formatPrice({ amount: item.amount, item: item }))+" "),(item.category === 1)?_c('p',[_vm._v(" ("+_vm._s(item.free_riding_time_minutes)+" Minutes) ")]):_vm._e()]),_c('FSTableRowItem',[_c('XStatus',{attrs:{"text":item.status,"variant":_vm.getItemVariant(item.status),"profile":"payment"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center gap-1"},[(item.status !== 'Expired')?_c('OtoEditIcon',{on:{"click":function($event){return _vm.edit(item)}}}):_vm._e(),_c('MoreActions',{attrs:{"data":item},on:{"refresh":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":_vm.getPassCategoryName(item.category),"to":{
                  name: 'PassDetails',
                  params: { id: item.id },
                },"target":"_blank"}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(_vm.formatPrice({ amount: item.amount, item: item }))+" "),(item.category === 1)?_c('p',[_vm._v(" ("+_vm._s(item.free_riding_time_minutes)+" Minutes) ")]):_vm._e()]),_c('FSTableRowItem',[_c('XStatus',{attrs:{"text":item.status,"variant":_vm.getItemVariant(item.status),"profile":"payment"}})],1)],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.subscriptionManagement.table.columns.id'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.subscriptionManagement.table.columns.fleet' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[(item.assigned_fleet)?_c('RouterLink',{key:item.assigned_fleet.id,staticClass:"text-blue-600 ",attrs:{"to":{
                        name: 'ViewFleetProfile',
                        params: { id: item.assigned_fleet.id },
                      },"target":"_blank"}},[_vm._v(_vm._s(item.assigned_fleet.name))]):[_c('div',[_vm._v(" -- ")])]],2)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.subscriptionManagement.table.columns.expireAt' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getDate(_vm.dateZuluFormatter(item.end_date)))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.subscriptionManagement.table.columns.timeLeft' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getTimesLeft(item.start_date, item.end_date))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.subscriptionManagement.table.columns.actions' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex items-center gap-1"},[(item.status !== 'Expired')?_c('OtoEditIcon',{on:{"click":function($event){return _vm.edit(item)}}}):_vm._e(),_c('MoreActions',{attrs:{"data":item},on:{"refresh":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1)])])])]):_vm._e()]:_vm._e()]})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }