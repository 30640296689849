export const PassConfig = {
  api: {
    // get
    index: '/dashboard/passes/',
    // post
    create: '/dashboard/passes/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/passes/${id}/`
    },
    detail: (id = 'uuid') => {
      return `/dashboard/passes/${id}/`
    },
    //delete
    delete: (id = 'uuid') => {
      return `/dashboard/passes/${id}/`
    },
  },
  events: {
    name: 'passes',
    // refresh-index-data
    refresh: `rid-passes`,
    // slideover-right
    sorId: 'passes',
    sorOpen: 'sor-open-passes',
    sorClose: 'sor-close-passes',
    sorToggle: 'sor-toggle-passes',
    // editing-data
    editingData: 'edit-passes-data',
    // viewing-data
    viewingData: 'view-passes-data',
  },
}
